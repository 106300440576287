var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "view-producer" }, [
    _c("section", [
      _c("div", { staticClass: "flexbox flexbox--center h-mb-20 gap-10" }, [
        _c(
          "button",
          {
            staticClass: "btn btn--sm btn--black btn--border",
            on: {
              click: function ($event) {
                _vm.showModalPdfAccountant = true
              },
            },
          },
          [_vm._v("Export PDF for Accountant")]
        ),
        _c(
          "button",
          {
            staticClass: "btn btn--sm btn--success btn--border",
            on: {
              click: function ($event) {
                _vm.showModalPayAll = true
              },
            },
          },
          [_vm._v("Mark All As Paid")]
        ),
      ]),
      _vm.selectedData && _vm.showModalPay
        ? _c(
            "div",
            { staticClass: "modal__container modal__container--overlay" },
            [
              _c(
                "div",
                { staticClass: "modalDialog" },
                [
                  _c("icon", {
                    staticClass: "modalcloseButton",
                    attrs: { name: "close" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.closeAllModals()
                      },
                    },
                  }),
                  _c(
                    "div",
                    {
                      staticClass:
                        "modalContent modalContent--sideBySideButtons",
                    },
                    [
                      _c("div", { staticClass: "modalContent__body" }, [
                        _c("h2", { staticClass: "modal__title" }, [
                          _vm._v("Payment"),
                        ]),
                        _c("p", { staticClass: "h-mb-10" }, [
                          _vm._v("Do you want to make "),
                          _c("strong", [
                            _vm._v(_vm._s(_vm.selectedData.display_name)),
                          ]),
                          _vm._v(" as paid? "),
                          _c("br"),
                          _vm._v(
                            " Amount: $" + _vm._s(_vm.selectedData.amount_due)
                          ),
                        ]),
                        _c(
                          "a",
                          {
                            staticClass: "btn btn--grey btn--border",
                            on: {
                              click: function ($event) {
                                return _vm.closeAllModals()
                              },
                            },
                          },
                          [_vm._v("CANCEL")]
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "btn btn--success",
                            on: {
                              click: function ($event) {
                                return _vm.payProducer(
                                  _vm.selectedData.producer_id
                                )
                              },
                            },
                          },
                          [_vm._v("YES")]
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm.showModalPayAll
        ? _c(
            "div",
            { staticClass: "modal__container modal__container--overlay" },
            [
              _c(
                "div",
                { staticClass: "modalDialog" },
                [
                  _c("icon", {
                    staticClass: "modalcloseButton",
                    attrs: { name: "close" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.closeAllModals()
                      },
                    },
                  }),
                  _c(
                    "div",
                    {
                      staticClass:
                        "modalContent modalContent--sideBySideButtons",
                    },
                    [
                      _c("div", { staticClass: "modalContent__body" }, [
                        _c("h2", { staticClass: "modal__title" }, [
                          _vm._v("Payment"),
                        ]),
                        _c("p", { staticClass: "h-mb-10" }, [
                          _vm._v("Do you want to pay all the artists?"),
                        ]),
                        _c(
                          "a",
                          {
                            staticClass: "btn btn--grey btn--border",
                            on: {
                              click: function ($event) {
                                return _vm.closeAllModals()
                              },
                            },
                          },
                          [_vm._v("CANCEL")]
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "btn btn--success",
                            on: {
                              click: function ($event) {
                                return _vm.payAllProducers()
                              },
                            },
                          },
                          [_vm._v("YES")]
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm.showModalPdfAccountant
        ? _c(
            "div",
            { staticClass: "modal__container modal__container--overlay" },
            [
              _c(
                "div",
                { staticClass: "modalDialog" },
                [
                  _c("icon", {
                    staticClass: "modalcloseButton",
                    attrs: { name: "close" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.closeAllModals()
                      },
                    },
                  }),
                  _c(
                    "div",
                    {
                      staticClass:
                        "modalContent modalContent--sideBySideButtons",
                    },
                    [
                      _c("div", { staticClass: "modalContent__body" }, [
                        _c("h2", { staticClass: "modal__title" }, [
                          _vm._v("PDF for accountant"),
                        ]),
                        _c("div", { staticClass: "formGroup" }, [
                          _c("p", { staticClass: "p" }, [
                            _vm._v("Which year?"),
                          ]),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.yearForPdf,
                                  expression: "yearForPdf",
                                },
                              ],
                              staticClass: "formSelect",
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.yearForPdf = $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                },
                              },
                            },
                            [
                              _c("option", { domProps: { value: 2019 } }, [
                                _vm._v("2019"),
                              ]),
                              _c("option", { domProps: { value: 2020 } }, [
                                _vm._v("2020"),
                              ]),
                              _c("option", { domProps: { value: 2021 } }, [
                                _vm._v("2021"),
                              ]),
                              _c("option", { domProps: { value: 2022 } }, [
                                _vm._v("2022"),
                              ]),
                              _c("option", { domProps: { value: 2023 } }, [
                                _vm._v("2023"),
                              ]),
                              _c("option", { domProps: { value: 2024 } }, [
                                _vm._v("2024"),
                              ]),
                              _c("option", { domProps: { value: 2025 } }, [
                                _vm._v("2025"),
                              ]),
                              _c("option", { domProps: { value: 2026 } }, [
                                _vm._v("2026"),
                              ]),
                              _c("option", { domProps: { value: 2027 } }, [
                                _vm._v("2027"),
                              ]),
                            ]
                          ),
                        ]),
                        _c("div", { staticClass: "formGroup" }, [
                          _c("p", { staticClass: "p" }, [
                            _vm._v("Which month?"),
                          ]),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.monthForPdf,
                                  expression: "monthForPdf",
                                },
                              ],
                              staticClass: "formSelect",
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.monthForPdf = $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                },
                              },
                            },
                            [
                              _c("option", { attrs: { value: "1" } }, [
                                _vm._v("January"),
                              ]),
                              _c("option", { attrs: { value: "2" } }, [
                                _vm._v("February"),
                              ]),
                              _c("option", { attrs: { value: "3" } }, [
                                _vm._v("March"),
                              ]),
                              _c("option", { attrs: { value: "4" } }, [
                                _vm._v("April"),
                              ]),
                              _c("option", { attrs: { value: "5" } }, [
                                _vm._v("May"),
                              ]),
                              _c("option", { attrs: { value: "6" } }, [
                                _vm._v("June"),
                              ]),
                              _c("option", { attrs: { value: "7" } }, [
                                _vm._v("July"),
                              ]),
                              _c("option", { attrs: { value: "8" } }, [
                                _vm._v("August"),
                              ]),
                              _c("option", { attrs: { value: "9" } }, [
                                _vm._v("September"),
                              ]),
                              _c("option", { attrs: { value: "10" } }, [
                                _vm._v("October"),
                              ]),
                              _c("option", { attrs: { value: "11" } }, [
                                _vm._v("November"),
                              ]),
                              _c("option", { attrs: { value: "12" } }, [
                                _vm._v("December"),
                              ]),
                            ]
                          ),
                        ]),
                        _c("div", { staticClass: "formGroup" }, [
                          _c("p", { staticClass: "p" }, [
                            _vm._v("Total paid: "),
                            _c("strong", [
                              _vm._v("$" + _vm._s(_vm.totalPayedOnMonth)),
                            ]),
                          ]),
                        ]),
                        _c("div", { staticClass: "formGroup h-mt-50" }, [
                          _c(
                            "a",
                            {
                              staticClass: "btn btn--grey btn--border",
                              on: { click: _vm.closeAllModals },
                            },
                            [_vm._v("Close")]
                          ),
                          _c(
                            "a",
                            {
                              staticClass: "btn btn--success",
                              on: { click: _vm.exportPdfForCommercialista },
                            },
                            [_vm._v("Go")]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "container container--fullWidth wrapper--filled" },
        [
          _c("table", [
            _vm._m(0),
            _c(
              "tbody",
              _vm._l(_vm.tableData, function (value, index) {
                return _c(
                  "tr",
                  {
                    staticClass: "beat-table payoutsTable",
                    class: { "to-be-paid": value.amount_due > 0 },
                  },
                  [
                    _c("td", { staticClass: "hideOnMobile" }, [
                      _c("p", [_vm._v(_vm._s(value.display_name))]),
                    ]),
                    _c("td", [_vm._v(_vm._s(value.paypal_email))]),
                    _c("td", { staticClass: "hideOnMobile" }, [
                      _c("span", { staticClass: "dollars" }, [_vm._v("$")]),
                      _vm._v(_vm._s(_vm._f("twoDecimals")(value.total_payed))),
                    ]),
                    _c("td", { staticClass: "hideOnMobile" }, [
                      _vm._v(_vm._s(value.last_payed)),
                    ]),
                    _c("td", { staticClass: "hideOnMobile" }, [
                      _c("span", { staticClass: "dollars" }, [_vm._v("$")]),
                      _vm._v(
                        _vm._s(_vm._f("twoDecimals")(value.affiliate_payouts))
                      ),
                    ]),
                    _c("td", { staticClass: "payoutsTable__sm" }, [
                      _c("span", { staticClass: "dollars" }, [_vm._v("$")]),
                      _vm._v(_vm._s(_vm._f("twoDecimals")(value.amount_due))),
                    ]),
                    _c("td", [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn--xs",
                          on: {
                            click: function ($event) {
                              return _vm.showModalSinglePayment(index)
                            },
                          },
                        },
                        [_vm._v("Mark as paid")]
                      ),
                    ]),
                  ]
                )
              }),
              0
            ),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", { staticClass: "beat-table payoutsTable" }, [
        _c("th", { staticClass: "hideOnMobile" }, [_vm._v("Producer")]),
        _c("th", [_vm._v("Paypal E-mail")]),
        _c("th", { staticClass: "hideOnMobile" }, [_vm._v("Tot. Paid")]),
        _c("th", { staticClass: "hideOnMobile" }, [_vm._v("Last payment")]),
        _c("th", { staticClass: "hideOnMobile" }, [
          _vm._v("Affiliate payouts"),
        ]),
        _c("th", { staticClass: "payoutsTable__sm" }, [_vm._v("Amount due")]),
        _c("th", [_vm._v("Actions")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }